<template>
  <div>
    <table :id="id" aria-label="oversigt over beregninger" class="table w-percent-100">
      <thead>
      <tr v-if="tableHeaders" class="bg-primary text-color-white">
        <th
            v-for="(tableHeader, index) in tableHeaders"
            :id="`table-header-${index}-${id}`"
            :key="tableHeader.sortKey"
            class="clickable"
            scope="col"
            @click="sort(tableHeader.sortKey)"
        >
          {{ tableHeader.headline }}
          <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
            <path
                :fill="currentSort === tableHeader.sortKey && currentSortDir === 'asc' ? 'white' : ''"
                clip-rule="evenodd"
                d="M9.99419 4.75003L6.00003 0.755859L2.00586 4.75003H9.99419Z"
                fill-rule="evenodd"
                stroke="white"
            />
            <path
                :fill="currentSort === tableHeader.sortKey && currentSortDir === 'desc' ? 'white' : ''"
                clip-rule="evenodd"
                d="M2.00586 7.25003L6.00003 11.2442L9.99419 7.25003H2.00586Z"
                fill-rule="evenodd"
                stroke="white"
            />
          </svg>
        </th>

        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="sortedCalculations.length === 0">
        <td :headers="getRowDataHeaderDefinition(id)" class="table-no-content" colspan="5"><SimpleText text-key='missing-calculations-in-list.message'/></td>
      </tr>
      <tr v-for="calculation in sortedCalculations" :key="calculation.id"
          :data-cy-name="calculation.calculationPurpose.name">
        <td :headers="`table-header-0-${id}`" v-if="calculation.calculationPurpose" data-title="Navn" style="width: 35%">
          {{ calculation.calculationPurpose.name }}
          <strong v-if="hasExtraInfoChange(calculation)"><br>{{ extraInfoChange(calculation) }}</strong>
          <strong v-if="hasExtraInfoECVersion(calculation)" class="ecv-version-text"><br>{{ extraInfoECVersion(calculation) }}</strong>
          <strong v-if="hasExtraInfoECYear(calculation)" class="ecv-year-text"><br>{{ extraInfoECYear(calculation) }}</strong>
          <strong v-if="isInFutureCalculation(calculation)" class="future-text">&nbsp;{{ extraInfoFurtureCalculation(calculation) }}</strong>
          <strong v-if="isUsedInComparison(calculation)" class="future-text">&nbsp;{{ extraInfoComparison(calculation) }}</strong>
        </td>
        <td :headers="`table-header-1-${id}`" v-if="calculation.calculationPurpose" data-title="Formål" style="width: 20%">
          {{ mapPurpose(calculation.calculationPurpose.purpose) }}
        </td>
        <td :headers="`table-header-2-${id}`" data-title="Lokation" style="width: 25%">
          <div v-if="calculation.companyInformation">
            <!--v-if="calculation.companyInformation.country === 'DK' || (value[0].fake && value[0].address.calculationActive)"-->

            <div
                v-for="(value, name) in calculationLocations(calculation)"
                :key="name"
                class="overflow-menu  overflow-menu--open-left w-percent-100"
            >
              <button
                  v-if="calculation.companyInformation.country === 'DK'"
                  :data-js-target="`calculation-${calculation.id}-${name}-overflow`"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="button-overflow-menu js-dropdown w-percent-100 location-button"
              >
                {{ name ? name : 'Ukendt' }}<span>, {{ getCountryName(calculation.companyInformation.country) }}</span>
                <svg class="icon-svg" style="float: right">
                  <use height="19px" width="16px" xlink:href="#location-on"></use>
                </svg>
              </button>

              <button
                  v-else-if="value[0].address.calculationActive"
                  :data-js-target="`calculation-${calculation.id}-${name}-overflow`"
                  aria-expanded="false"
                  aria-haspopup="true"
                  class="button-overflow-menu js-dropdown w-percent-100 location-button"
              >
                {{ name ? name : 'Ukendt' }}<span>, {{ getCountryName(calculation.companyInformation.country) }}</span>
                <svg class="icon-svg" style="float: right">
                  <use height="19px" width="16px" xlink:href="#location-on"></use>
                </svg>
              </button>

              <div
                  v-if="calculation.companyInformation.country === 'DK'"
                  :id="`calculation-${calculation.id}-${name}-overflow`"
                  :class="`calculation-${name}-overflow`"
                  aria-hidden="true"
                  class="overflow-menu-inner"
              >
                <strong style="padding-left: 1rem">
                  {{ name ? name : 'Ukendt' }}<span>, {{ getCountryName(calculation.companyInformation.country) }}</span>
                </strong>
                <ul v-for="productionUnit in value" :key="productionUnit.pNumber" class="overflow-list">
                  <li></li>
                  <li style="text-align: left; padding-left: 1rem;">
                    <strong>{{ `P-${productionUnit.pNumber}:` }}</strong>
                    <br/>
                    {{
                      `${productionUnit.address.street ? productionUnit.address.street : ''} ${productionUnit.address.houseNumberFrom ? productionUnit.address.houseNumberFrom : ''}`
                    }}, <br/>
                    {{ `${productionUnit.address.zipCode ? productionUnit.address.zipCode : ''} ${productionUnit.address.city ? productionUnit.address.city : ''}` }}
                  </li>
                </ul>
              </div>
              <div
                  v-else
                  :id="`calculation-${calculation.id}-${name}-overflow`"
                  :class="`calculation-${name}-overflow`"
                  aria-hidden="true"
                  class="overflow-menu-inner"
              >
                <strong style="padding-left: 1rem">
                  {{ name }}<span>, {{ getCountryName(calculation.companyInformation.country) }}</span>
                </strong>
                <ul v-for="productionUnit in value" :key="productionUnit.pNumber" class="overflow-list">
                  <li></li>
                  <li v-if="productionUnit.address.calculationActive" style="text-align: left; padding-left: 1rem;">
                      <span>{{ productionUnit.address.street }}</span
                      ><br/>
                    <span v-if="productionUnit.address.placeName">{{ productionUnit.address.placeName }}</span
                    ><br v-if="productionUnit.address.placeName"/>
                    <span>{{ productionUnit.address.zipCode }} {{ productionUnit.address.city }}</span
                    ><br v-if="productionUnit.address.state"/>
                    <span v-if="productionUnit.address.state">{{ productionUnit.address.state }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </td>
        <td :headers="`table-header-3-${id}`"
            v-if="calculation.calculationPurpose"
            data-title="Periode"
            style="width: 20%"
            v-html="formatDates(calculation.calculationPurpose.startDate, calculation.calculationPurpose.endDate)"
        />
        <td :headers="`table-header-3-${id}`" data-title="Handlinger" :class="isCopy(calculation) || isNewECVersion(calculation) ? 'marked-as-copy' : ''">
          <div class="overflow-menu overflow-menu--open-left">
            <button
                :class="`overflow-menu-table`"
                :data-js-target="`calculation-${calculation.id}-actions-overflow`"
                aria-expanded="false"
                aria-haspopup="true"
                aria-label="handlinger"
                class="button-overflow-menu js-dropdown "
            >
              <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                <use xlink:href="#more-vert"></use>
              </svg>
            </button>
            <div :id="`calculation-${calculation.id}-actions-overflow`" aria-hidden="true" class="overflow-menu-inner">
              <!-- List of possible events to apply to a 'draft' calculation START -->
              <ul v-if="calculation.state === 'draft' || calculation.state === 'duplicated'" class="overflow-list">
                <li style="padding-top: 0">
                  <button v-if="isNewECVersion(calculation)" :id="`update-ecv-${calculation.id}-button`" class="update-ecv-button" @click="$emit('update-ecv-calculation', calculation)">
                    <SimpleText text-key="overview.menu.ecv.update"/>
                    <svg aria-hidden="true" :id="`refresh-icon-${calculation.id}`" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#refresh"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <router-link v-if="isCalculationEcVersionAllowedForRecalculation(calculation)" class="edit-button" to="/calculator/energy-and-processes/electricity" @click="$emit('edit-calculation', calculation)">
                    <SimpleText text-key="calculationsoverview.edit"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </router-link>
                  <button v-else class="edit-button">
                    <Tooltip :text="this.updateEcVersionBeforeEditTooltip" :initDkfds="false" />
                    <span class="disabled no-pointer-events">
                      <SimpleText text-key="calculationsoverview.edit"/>
                    </span>
                    <svg aria-hidden="true" class="icon-svg ml-3 disabled" focusable="false">
                      <use xlink:href="#mode"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <button class="copy-button" @click="$emit('copy-calculation', calculation)">
                    <SimpleText text-key="shared.copy"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#content-copy"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <button class="delete-button" @click="$emit('delete-calculation', calculation)">
                    <SimpleText text-key="shared.delete"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#trash-can"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <button v-if="!calculation.hasCalculationLines" class="unlock-button">
                    <Tooltip :text="getContentfulString('overview.menu.empty-calculation')" :initDkfds="false" />
                    <span class="disabled pointer-events-none">
                      <SimpleText text-key="calculationsoverview.finish"/>
                    </span>
                    <svg aria-hidden="true" class="icon-svg ml-3 disabled" focusable="false">
                      <use xlink:href="#lock"></use>
                    </svg>
                  </button>
                  <button v-else class="finish-button" @click="$emit('complete-calculation', calculation)">
                    <SimpleText text-key="calculationsoverview.finish"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#lock"></use>
                    </svg>
                  </button>
                </li>
              </ul>

              <!-- List of possible events to apply to a 'finished' calculation START -->
              <ul v-if="calculation.state === 'finished' || calculation.state === 'readonly' || calculation.state === 'finished_duplicated'" class="overflow-list">
                <li>
                  <div v-if="isNewECVersion(calculation)">
                    <div v-if="!isCalculationForecasted(calculation)">
                      <button id="update-ecv" class="update-ecv-button" @click="$emit('update-ecv-calculation', calculation)">
                        <SimpleText text-key="overview.menu.ecv.update"/>
                        <svg aria-hidden="true" :id="`refresh-icon-${calculation.id}-finished`" class="icon-svg ml-3" focusable="false">
                          <use xlink:href="#refresh"></use>
                        </svg>
                      </button>
                    </div>
                    <div v-else class="ecv-update-disabled">
                      <button disabled="true" :id="`update-ecv-disable-${calculation.id}-button`">
                        <Tooltip :text="this.forecastedCannotUpdateEcVersionTooltip" :initDkfds="false"/>
                        <span class="disabled no-pointer-events">
                          <SimpleText text-key="overview.menu.ecv.update"/>
                          <svg aria-hidden="true" :id="`refresh-icon-${calculation.id}-disabled`" class="icon-svg ml-3" focusable="false">
                            <use xlink:href="#refresh"></use>
                          </svg>
                        </span>
                      </button>
                    </div>
                  </div>
                </li>
                <li>
                  <div style="padding: 8px 16px; width: 100%; text-align: right">
                    <Tooltip :text="this.deleteTooltip" :initDkfds="false">
                      <template v-slot:text-after>
                          <span class="disabled">
                            <SimpleText text-key="shared.delete"/>
                          </span>
                        <svg aria-hidden="true" class="icon-svg ml-3 disabled" focusable="false" style="float:none">
                          <use xlink:href="#trash-can"></use>
                        </svg>
                      </template>
                    </Tooltip>
                  </div>
                </li>
                <li>
                  <button class="copy-button" @click="$emit('copy-calculation', calculation)">
                    <SimpleText text-key="shared.copy"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#content-copy"></use>
                    </svg>
                  </button>
                </li>
                <li>
                  <button class="show-button" @click="$emit('show-calculation', calculation)">
                    <SimpleText text-key="shared.show"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#visibility"></use>
                    </svg>
                  </button>
                </li>

                <li>
                  <button v-if="isInFutureCalculation(calculation) || isUsedInComparison(calculation)" class="unlock-button">
                    <Tooltip :text="tooltip" :initDkfds="false" />
                    <span class="disabled no-pointer-events">
                      <SimpleText text-key="calculationsoverview.unlock"/>
                    </span>
                    <svg aria-hidden="true" class="icon-svg ml-3 disabled" focusable="false">
                      <use xlink:href="#lock-open"></use>
                    </svg>
                  </button>
                  <button v-else class="unlock-button" @click="$emit('reopen-calculation', calculation)">
                    <SimpleText text-key="calculationsoverview.unlock"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#lock-open"></use>
                    </svg>
                  </button>
                </li>
              </ul>
              <!-- List of possible events to apply to a 'finished' calculation END -->
              <!-- forecasted -->
              <ul v-if="calculation.state === 'forecasted'" class="overflow-list">
                <li>
                  <button class="show-button" @click="$emit('show-calculation', calculation)">
                    <SimpleText text-key="shared.show"/>
                    <svg aria-hidden="true" class="icon-svg ml-3" focusable="false">
                      <use xlink:href="#visibility"></use>
                    </svg>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import {Options} from "vue-class-component"
import {Prop} from "vue-property-decorator"
import TableHeader from "@/types/TableHeader"
import * as DKFDS from "dkfds"
import {
  Calculation,
  CalculationPurposePurposeEnum,
  CalculationStateEnum,
  Country,
  ProductionUnit,
  Versions
} from "openapi/generated-clients/climatecompass"
import {countryClient} from "@/shared/BackendService"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import RichText from "@/components/RichText.vue";
import SimpleText from "@/components/SimpleText.vue";

@Options({
  emits: ["reopen-calculation", "delete-calculation", "show-calculation", "edit-calculation", "complete-calculation", "copy-calculation", "update-ecv-calculation"],
  components: {
    RichText,
    SimpleText,
  }
})

export default class OverviewTableComponent extends BaseComponent {
  @Prop({required: true})
  calculations: Calculation[] = []
  @Prop({required: true})
  versions = {} as Versions
  @Prop({required: true})
  showOnly = ""
  @Prop()
  id?: string

  tableHeaders = [] as TableHeader[]

  countries: Country[] = []

  currentSort = "name"
  currentSortDir = "asc"
  showForecastItems = false



  isCopy(calculation: Calculation) {
    if (!calculation.state) {
      return false
    }
    if (calculation.state.toUpperCase() === 'DUPLICATED' || calculation.state.toUpperCase() === 'FINISHED_DUPLICATED') {
      return true
    } else {
      return false
    }
  }

  isCalculationForecasted(calculation: Calculation) {
    //console.log("isCalculationForecasted [" + calculation.calculationPurpose.name + "], forecasted state: " + calculation?.isBasisForForecast, calculation)
    if (calculation?.isBasisForForecast) {
      return true
    } else {
      return false
    }
  }

  isNewECVersion(calculation: Calculation) {
    if (calculation && calculation.availableEmissionCoefficientsVersion) {
      if (calculation.availableEmissionCoefficientsVersion.year === calculation.emissionCoefficientsVersion?.year) {
        if (calculation.availableEmissionCoefficientsVersion.version !== calculation.emissionCoefficientsVersion.version) {
          return true
        }
      } else {
        return true
      }
    }
    return false
  }

  isNewECYear(calculation: Calculation) {
        if (calculation && calculation.availableEmissionCoefficientsVersion) {
      if (calculation.availableEmissionCoefficientsVersion.year !== calculation.emissionCoefficientsVersion?.year) {
        return true
      }
    }
    return false
  }

  padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  extraInfoChange(calculation: Calculation) {
        if (calculation) {
      if (calculation.showCreatedBy && calculation.createdByName && calculation.createdTime) {
        const ct = new Date(calculation.createdTime)
        const cDate = this.padTo2Digits(ct.getDate()) + "/" + this.padTo2Digits(ct.getMonth() + 1) + "/" + ct.getFullYear()
        if (calculation.parentId) {
          return this.getContentfulString("thirdparty.overview.copied-by.text")
            .replace("#createdByName#", calculation.createdByName)
            .replace("#createdTime#", cDate)
        } else {
          if (this.createdByText) {
            return this.createdByText
              .replace("#createdByName#", calculation.createdByName)
              .replace("#createdTime#", cDate)
          }
        }
      }
      if (calculation.showUpdatedBy && calculation.updatedByName && calculation.updatedTime) {
        const ut = new Date(calculation.updatedTime)
        const uDate = this.padTo2Digits(ut.getDate()) + "/" + this.padTo2Digits(ut.getMonth() + 1) + "/" + ut.getFullYear()
        if (this.updatedByText) {
          return this.updatedByText
              .replace("#updatedByName#", calculation.updatedByName)
              .replace("#updatedTime#", uDate)
        }
      }
    }
    return ""
  }

  hasExtraInfoChange(calculation: Calculation) {
    //console.log('hasExtraInfoChange', calculation.calculationPurpose.name)
    if (calculation && calculation.showCreatedBy) {
        return true
    }
    if (calculation && calculation.showUpdatedBy) {
        return true
    }
    return false
  }

  extraInfoECVersion(calculation: Calculation) {
        if (this.hasExtraInfoECVersion(calculation)) {
      return this.getContentfulString('overview.ecv.warning1')
    } else {
      return ""
    }
  }

  get tooltip() {
    return this.getContentfulString('overview.ecv.forecast.reopen.tooltip')
  }

  hasExtraInfoECVersion(calculation: Calculation) {
    const fo = "forecasted" as CalculationStateEnum
    if (calculation && calculation.state == fo && this.showForecastItems) {
      return false
    }

        //    calculation.availableEmissionCoefficientsVersion?.version, calculation.emissionCoefficientsVersion?.version,
    //    calculation)
    if (calculation && calculation.availableEmissionCoefficientsVersion) {
      if (calculation.availableEmissionCoefficientsVersion.year === calculation.emissionCoefficientsVersion?.year) {
        if (calculation.availableEmissionCoefficientsVersion.version !== calculation.emissionCoefficientsVersion.version) {
          return true
        }
      }
    }
    return false
  }

  extraInfoECYear(calculation: Calculation) {
        if (calculation && calculation.availableEmissionCoefficientsVersion && this.hasExtraInfoECYear(calculation)) {
      return this.getContentfulString('overview.ecv.warning2').replace('#YEAR#', this.getStartYear(calculation))
    } else {
      return ""
    }
  }

  extraInfoFurtureCalculation(calculation: Calculation) {
        if (this.isInFutureCalculation(calculation)) {
      return this.getContentfulString('overview.ecv.forecast')
    } else {
      return ""
    }
  }

  extraInfoComparison(calculation: Calculation) {
    if (this.isUsedInComparison(calculation)) {
      return this.getContentfulString('overview.ecv.comparison')
    } else {
      return ""
    }
  }

  hasExtraInfoECYear(calculation: Calculation) {
        //    calculation.availableEmissionCoefficientsVersion?.year, calculation.emissionCoefficientsVersion?.year,
    //    this.getStartYear(calculation))
    const startYear = this.getStartYear(calculation);
    const endYear = this.getEndYear(calculation)
    const fo = "forecasted" as CalculationStateEnum
    if (calculation && calculation.state == fo && this.showForecastItems) {
      return false
    }
    if (startYear) {
      if ((startYear !== calculation.emissionCoefficientsVersion?.year && endYear !==calculation.emissionCoefficientsVersion?.year) && this.versions.versions?.find(v => v.year === startYear)) {
        return true
      }
    }
    return false
  }

  isInFutureCalculation(calculation: Calculation) {
        if (calculation && calculation.isBasisForForecast) {
        return true
    }
    return false
  }

  isUsedInComparison(calculation: Calculation) {
    if (calculation && calculation.isUsedInComparison) {
      return true
    }
    return false
  }

  getRowDataHeaderDefinition(id: any) {
    return 'table-header-0-' + id + ' ' + 'table-header-1-' + id + ' ' + 'table-header-2-' + id + ' ' + 'table-header-3-' + id
  }

  get sortedCalculations() {
    const _calculations = [...this.calculations.filter(this.isFiltered)]

    if (this.currentSort === "purpose") {
      return _calculations.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === "desc") {
          modifier = -1
        }

        const aMappedPurpose = this.mapPurpose(a.calculationPurpose.purpose)
        const bMappedPurpose = this.mapPurpose(b.calculationPurpose.purpose)

        if (aMappedPurpose && bMappedPurpose && aMappedPurpose! < bMappedPurpose!) {
          return -1 * modifier
        } else if (aMappedPurpose && bMappedPurpose && aMappedPurpose! > bMappedPurpose!) {
          return 1 * modifier
        }
        return 0
      })
    }

    if (this.currentSort === "name") {
      return _calculations.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === "desc") {
          modifier = -1
        }
        const termA = a.calculationPurpose.name
        const termB = b.calculationPurpose.name
        return termA.localeCompare(termB, "da-DK") * modifier
      })
    }

    if (this.currentSort === "city") {
      return _calculations.sort((a, b) => {
        let modifier = 1
        if (this.currentSortDir === "desc") {
          modifier = -1
        }
        if (!a.companyInformation?.productionUnits?.length) {
          return -1 * modifier
        }
        if (!b.companyInformation?.productionUnits?.length) {
          return 1 * modifier
        }

        if (!a.companyInformation.productionUnits[0]?.address) {
          return -1 * modifier
        }
        if (!a.companyInformation.productionUnits[0]?.address) {
          return 1 * modifier
        }

        if ([...a.companyInformation.productionUnits].sort()[0].address.city < [...b.companyInformation.productionUnits].sort()[0].address.city) {
          return -1 * modifier
        }
        if ([...a.companyInformation.productionUnits].sort()[0].address.city > [...b.companyInformation.productionUnits].sort()[0].address.city) {
          return 1 * modifier
        }

        return 0
      })
    }

    return _calculations.sort((a, b) => {
      let modifier = 1
      if (this.currentSortDir === "desc") {
        modifier = -1
      }

      if (this.getKeyValue(this.currentSort)(a.calculationPurpose) < this.getKeyValue(this.currentSort)(b.calculationPurpose)) {
        return -1 * modifier
      }
      if (this.getKeyValue(this.currentSort)(a.calculationPurpose) > this.getKeyValue(this.currentSort)(b.calculationPurpose)) {
        return 1 * modifier
      }

      return 0
    })
  }

  getKeyValue = (key: string) => (obj: Record<string, any>) => obj[key]

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  async beforeMount() {
    this.tableHeaders = [
      {headline: this.name, sortKey: "name", id: "name"},
      {headline: this.purpose, sortKey: "purpose", id: "purpose"},
      {headline: this.location, sortKey: "city", id: "location"},
      {headline: this.period, sortKey: "startDate", id: "period"}
    ]

    await countryClient
        .fetchCountries()
        .then((response) => {
          this.countries = response.data.countries ?? []
        })
        .catch((error) => {
          console.error(error)
        })

  }

  getCountryName(countryCode: string) {
    if (this.countries && this.countries.length > 0) {
      return (
          this.countries.find((country: Country) => {
            return country.code === countryCode
          })?.name || countryCode
      )
    }
  }

  groupByCity(productionUnits: ProductionUnit[]) {
    const reducedItems = productionUnits.filter((item) => item.address.calculationActive)
    const _groups = reducedItems.sort().reduce((acc: any, productionUnit: ProductionUnit) => {
      ;(acc[productionUnit.address.city] = acc[productionUnit.address.city] || []).push(productionUnit)
      return acc
    }, {})

    return Object.keys(_groups)
        .sort()
        .reduce((acc: any, key) => {
          acc[key] = _groups[key]
          return acc
        }, {})
  }

  calculationLocations(calculation: Calculation) {
    return this.groupByCity(calculation.companyInformation!.productionUnits || [])
  }

  mapPurpose(purposeText: string) {
    switch (purposeText as CalculationPurposePurposeEnum) {
      case "realClimateFootprint":
        return this.purposeMappingFootprint

      case "CO2reducingMeasures":
        return this.purposeMappingCo2initiative

      case "forecast":
        return this.purposeMappingForecast

      case "studyOrOther":
        return this.purposeMappingStudy

      default:
        return this.purposeMappingFootprint
    }
  }

  isFiltered(calculation: Calculation) {
    if (calculation.state === this.showOnly) {
      return true
    } else if (calculation.state === 'duplicated' && this.showOnly === 'draft') {
      return true
    } else if (this.showOnly === "finished" || this.showOnly === "finished_duplicated") {
      const ro = "readonly" as CalculationStateEnum
      const fi = "finished" as CalculationStateEnum
      const fiDup = "finished_duplicated" as CalculationStateEnum
      const fo = "forecasted" as CalculationStateEnum
      if (calculation.state == fo && this.showForecastItems) {
        return true
      }
      if (calculation.state == ro || calculation.state == fi || calculation.state == fiDup) {
        return true
      }
    } else {
      return false
    }
  }

  sort(sortBy: string) {
    if (sortBy === this.currentSort) {
      this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc"
    }
    this.currentSort = sortBy
  }

  get createdByText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.createdByText')
  }

  get updatedByText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.updatedByText')
  }

  get deleteTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.deleteTooltip')
  }

  get updateEcVersionBeforeEditTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.updateEcVersionBeforeEditTooltip')
  }

  get forecastedCannotUpdateEcVersionTooltip() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.forecastedCannotUpdateEcVersionTooltip')
  }

  get name() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.name')
  }

  get purpose() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.purpose')
  }

  get location() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.location')
  }

  get period() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.period')
  }

  get purposeMappingFootprint() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.purposeMappingFootprint')
  }

  get purposeMappingCo2initiative() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.purposeMappingCo2initiative')
  }

  get purposeMappingForecast() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.purposeMappingForecast')
  }

  get purposeMappingStudy() {
    return this.store.getters.getContentfulContent.findSimpleText('calculationsoverview.purposeMappingStudy')
  }

}
</script>

<style lang="scss" scoped>

.clickable {
  cursor: pointer;
}

.table {
  border: 2px solid $color-primary;
}

.table th {
  border-right-color: white;
}

.table th,
.table td {
  border-bottom: 1px solid $color-primary;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid white;
  width: 1rem;
  padding: 8px 0;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.location .button-overflow-menu {
  text-decoration: underline;
}

.location .button-overflow-menu:focus {
  font-weight: bold;
}

.overflow-menu-inner li {
  text-align: left;
  padding: 0.25rem 0rem;
}

.overflow-menu-inner {
  padding-top: 0 !important;
  overflow-y: auto;
  max-height: 40rem;
}

.disabled {
  opacity: 0.3;
}
.pointer-events-none {
  pointer-events: none;
}

.marked-as-copy {
  background-color: $color-calculation-copy !important;
}

.ecv-version-text {
  background-color: $color-warning-secondary;
}

.ecv-year-text {
  background-color: lightblue;
}

.future-text {
  background-color: black;
  color: white;
}

.ecv-update-disabled {
  background-color: #ffeecc;
  border-left: 10px $color-calculation-copy;
  width: 100%;
  overflow-wrap: anywhere;
  display: flex;
}

.update-ecv-button {
  background-color: #ffeecc !important;
  border-left: 10px $color-calculation-copy !important;
  width: 100% !important;
  overflow-wrap: anywhere;
  display: flex;
}

[id^="refresh-icon-"] {
 /* targets all refresh-icons on page, regardless of autogenerated ID number. */
  align-self: center;
  min-height: 24px;
  min-width: 24px;
}

</style>
