<template id="baseCalculatorComponent">
  <template v-if="showEFSelection">
  <div class="" style="width: 50%; display: flex;">
    <div class="no-line-break">
      <strong><SimpleText text-key="sharedContent.emissionsTitle"/></strong>
    </div>
    <div style="width:50%; position:relative; top:-5px;">
      <SwitchToggle
        :checked="currentEF === 'Miljødeklaration'"
        :countryLocked="country !== 'DK'"
        :leftLabel="this.electricityEnvironmentDeclarationTrue ?? ''"
        :rightLabel="this.electricityEnvironmentDeclarationFalse ?? ''"
        fieldId="metodevalgId"
        @update:checked="setEF"
      />
    </div>
    <div class="" style="width: 10%">
      <Tooltip :text="this.calculatorPage.chooseEmissionFactorTooltip" size="lg-h3" />
    </div>
  </div>
  </template>
  <template v-if="showRFISelection">
    <h3 v-if="pageProperties.chooseEmissionFactor">
      {{ pageProperties.chooseEmissionFactor }}
    </h3>
    <div class="" style="width: 50%; display: flex;">
      <div class="no-line-break">
        <strong><SimpleText text-key="sharedContent.emissionsTitle"/></strong>
      </div>
      <div style="width:50%; position:relative; top:-5px;">
        <SwitchToggle
          :checked="currentRFI === 'includeRfi'"
          :countryLocked="false"
          :leftLabel="this?.rfiTrue"
          :rightLabel="this?.rfiFalse"
          fieldId="metodevalgId2"
          @update:checked="setRFI"
        />
      </div>
      <div class="" style="width: 10%">
        <Tooltip :text="calculatorPageTransport.chooseEmissionFactorTooltip" size="lg-h3"/>
      </div>
    </div>
  </template>
  <h2 v-if="(calculatorPrimaryFirst.title || calculatorPrimarySecond.title || calculatorPrimaryThird.title) && pageProperties.title">
    {{ pageProperties.title }}

    <Tooltip :text="this.getTooltip(pageProperties.titleTooltip, this.contentfulId)"  size="lg" />
  </h2>

  <slot name="calculator-primary-1" :title="calculatorPrimaryFirst.title" :tooltip="this.getTooltip(calculatorPrimaryFirst.titleTooltip, calculatorPrimaryFirstContentfulId)" ></slot>
  <slot name="calculator-primary-2" :title="calculatorPrimarySecond.title" :tooltip="this.getTooltip(calculatorPrimarySecond.titleTooltip, calculatorPrimarySecondContentfulId)"></slot>
  <slot name="calculator-primary-3" :title="calculatorPrimaryThird.title" :tooltip="this.getTooltip(calculatorPrimaryThird.titleTooltip, calculatorPrimaryThirdContentfulId)"></slot>
  <div v-if="calculatorSecondaryFirst.title || calculatorSecondarySecond.title">
    <h2 v-if="pageProperties.secondaryTitle" class="mt-9">
      {{ pageProperties.secondaryTitle }}
      <Tooltip :text="pageProperties.secondaryTitleTooltip" size="lg" />
    </h2>

    <div class="w-percent-100 w-percent-100-child"
         v-html="documentToHtmlString(pageProperties.secondaryDescription)"></div>
    <slot name="calculator-secondary-1" :title="calculatorSecondaryFirst.title" :tooltip="this.getTooltip(calculatorSecondaryFirst.titleTooltip, calculatorSecondaryFirstContentfulId)"></slot>
    <slot name="calculator-secondary-2" :title="calculatorSecondarySecond.title" :tooltip="this.getTooltip(calculatorSecondarySecond.titleTooltip, calculatorSecondarySecondContentfulId)"></slot>
  </div>
  <NextAndBack :from="from" :to="to" />
</template>
<script lang="ts">
import { Options } from "vue-class-component"
import {Prop, Watch} from "vue-property-decorator";
import {Field, FieldEnum} from "../../../openapi/generated-clients/climatecompass";
import CalculatorInputField from "@/types/contentful-types/CalculatorInputField";
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import CalculatorPage from "@/types/contentful-types/CalculatorPage";
import CalculatorTab from "@/types/contentful-types/CalculatorTab";
import CalculatorComponent from "@/types/contentful-types/CalculatorComponent";
import NextAndBack from "@/components/NextAndBack.vue"
import SwitchToggle from "@/components/SwitchToggle.vue"
import SimpleText from "@/components/SimpleText.vue";

interface CombinedField extends Field, CalculatorInputField {
  value: string
  selectedDependents?: FieldEnum[]
}

@Options({
  components: {
    NextAndBack,
    SwitchToggle,
    SimpleText
  }
})
export default class CalculatorTabComponent extends BaseComponent {
  pageProperties: CalculatorTab = {} as CalculatorTab
  calculatorPage: CalculatorPage = {} as CalculatorPage
  calculatorPageTransport: CalculatorPage = {} as CalculatorPage
  @Prop()
  showRFISelection?: boolean
  @Prop()
  showEFSelection?: boolean
  @Prop()
  pagePropertiesSysId?: string
  @Prop()
  calculatorPrimaryFirstSysId?: string
  @Prop()
  calculatorPrimarySecondSysId?: string
  @Prop()
  calculatorPrimaryThirdSysId?: string
  @Prop()
  calculatorSecondaryFirstSysId?: string
  @Prop()
  calculatorSecondarySecondSysId?: string
  @Prop()
  from?: string
  @Prop()
  to?: string

  calculatorPrimaryFirst = {} as CalculatorComponent
  calculatorPrimarySecond = {} as CalculatorComponent
  calculatorPrimaryThird = {} as CalculatorComponent
  calculatorSecondaryFirst = {} as CalculatorComponent
  calculatorSecondarySecond = {} as CalculatorComponent
  calculatorPrimaryFirstContentfulId = ""
  calculatorPrimarySecondContentfulId = ""
  calculatorPrimaryThirdContentfulId = ""
  calculatorSecondaryFirstContentfulId = ""
  calculatorSecondarySecondContentfulId = ""
  contentfulId = ""

  getTooltip(input : string | undefined, id : string) {
    if (this.useTextKeys && id) {
      return input + " ContentfulId: " + id
    } else {
      return input
    }
  }

  async beforeMount() {
    this.eventHub.off('ef-updated')
    this.eventHub.off('rfi-updated')
    await this.initTab()
  }

  async initTab() {
    const full = await this.getPageFull(this.pagePropertiesSysId ?? "")
    this.contentfulId = full.sys.id
    this.pageProperties = full.fields //await this.getPage(this.pagePropertiesSysId ?? "")
    this.calculatorPage = await this.getPage("A0MRWFEtnWTI4Zf1n2fUJ")
    this.calculatorPageTransport = await this.getPage<CalculatorPage>("2Whp0fIGhAXWKQQnvPHPmd")

    const sessionTitles = []
    if(this.calculatorPrimaryFirstSysId) {
      const full = await this.getPageFull(this.calculatorPrimaryFirstSysId)
      //this.calculatorPrimaryFirst = await this.getPage(this.calculatorPrimaryFirstSysId)
      this.calculatorSecondaryFirstContentfulId = full.sys.id
      this.calculatorPrimaryFirst = full.fields
      sessionTitles.push(this.calculatorPrimaryFirst.title)
    }
    if(this.calculatorPrimarySecondSysId) {
      const full = await this.getPageFull(this.calculatorPrimarySecondSysId)
      //this.calculatorPrimarySecond = await this.getPage(this.calculatorPrimarySecondSysId)
      this.calculatorPrimarySecondContentfulId = full.sys.id
      this.calculatorPrimarySecond = full.fields
      sessionTitles.push(this.calculatorPrimarySecond.title)
    }
    if(this.calculatorPrimaryThirdSysId) {
      const full = await this.getPageFull(this.calculatorPrimaryThirdSysId)
      //this.calculatorPrimaryThird = await this.getPage(this.calculatorPrimaryThirdSysId)
      this.calculatorPrimaryThirdContentfulId = full.sys.id
      this.calculatorPrimaryThird = full.fields
      sessionTitles.push(this.calculatorPrimaryThird.title)
    }
    if(this.calculatorSecondaryFirstSysId) {
      const full = await this.getPageFull(this.calculatorSecondaryFirstSysId)
      //this.calculatorSecondaryFirst = await this.getPage(this.calculatorSecondaryFirstSysId)
      this.calculatorSecondaryFirstContentfulId = full.sys.id
      this.calculatorSecondaryFirst = full.fields
      sessionTitles.push(this.calculatorSecondaryFirst.title)
    }
    if(this.calculatorSecondarySecondSysId) {
      const full = await this.getPageFull(this.calculatorSecondarySecondSysId)
      //this.calculatorSecondarySecond = await this.getPage(this.calculatorSecondarySecondSysId)
      this.calculatorSecondarySecondContentfulId = full.sys.id
      this.calculatorSecondarySecond = full.fields
      sessionTitles.push(this.calculatorSecondarySecond.title)
    }

    // Should always be "true" i.e. environmental declaration if not DK
    this.setDocumentTitle(this.pageProperties.title)

    await this.store.dispatch("setCurrentCalculationPageData", sessionTitles)
  }

  @Watch('pagePropertiesSysId')
  async watchcalculatorPrimaryFirstSysId() {
    await this.initTab()
  }

  get electricityEnvironmentDeclarationTrue() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.electricityEnvironmentDeclarationTrue')
  }

  get electricityEnvironmentDeclarationFalse() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.electricityEnvironmentDeclarationFalse')
  }

  get rfiTrue() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.rfiTrue')
  }

  get rfiFalse() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.rfiFalse')
  }

}
</script>
<style scoped>

.no-line-break {
  line-break: unset;
  -webkit-line-break: unset;
  padding-right: 10px;
}

</style>