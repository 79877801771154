 <template>
  <div :class="{'edit': this.content.isEdit}">
    <div>
      <div class="mt-5">
        <table :aria-label="'beregning_' + getId" :id="'beregning_' + getId" :class="isCopy(this.content) ? 'table marked-border-as-copy' : 'table'">
          <thead>
          <tr class="bg-white row-one-headers">
            <th
                v-for="(col, index) in this.content.rowColHeaders[0]"
                :key="'header_row1_' + index"
                :aria-label="'Række' + (index + 1)"
                :id="'beregning-' + getId + '-Række' + (index + 1)"
                scope="col"
                v-html="col"
            ></th>
            <th scope="col" :class="isCopy(this.content) ? 'marked-as-copy' : ''">
              <div v-if="!this.content.readonly" class="overflow-menu overflow-menu--open-left">
                <button
                    :data-js-target="`actions-overflow-${getId}`"
                    aria-expanded="false"
                    aria-haspopup="true"
                    aria-label="Beregningsmenu"
                    class="button-overflow-menu js-dropdown"
                >
                  <svg aria-hidden="true" class="icon-svg" fill="white" focusable="false">
                    <use xlink:href="#more-vert"></use>
                  </svg>
                </button>
                <div :id="`actions-overflow-${id}`" aria-hidden="true" class="overflow-menu-inner">
                  <ul class="overflow-list">
                    <li>
                      <button @click="$emit('edit-click')">
                        <SimpleText text-key="sharedContent.edit"/>
                        <svg aria-hidden="true" class="icon-svg" focusable="false">
                          <use xlink:href="#mode"></use>
                        </svg>
                      </button>
                    </li>
                    <li v-if="aiActive">
                      <button @click="aiClick(this.content)">
                        AI
                        <svg aria-hidden="true" class="icon-svg" focusable="false">
                          <use xlink:href="#mode"></use>
                        </svg>
                      </button>
                    </li>
                    <li>
                      <button @click="confirmDeleteCalculation()">
                        <SimpleText text-key="sharedContent.delete"/>
                        <svg aria-hidden="true" class="icon-svg" focusable="false">
                          <use xlink:href="#trash-can"></use>
                        </svg>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="row-one-data">
            <td :headers="'beregning-' + getId + '-Række' + (index + 1)" :data-cy-inner="index > 0 ? doPrettify(row, 2) : row" :data-cy-id="'Felt' + (index + 1)" v-for="(row, index) in this.content.rowColData[0]"
                :key="'row1-data-' + index" :class="{ 'text-align-right': !isNaN(Number(row)) && index > 0 }">
              {{
                index > 0 && row !== '' ? doPrettify(this.getTranslation(row, this.content), 2) : this.getTranslation(row, this.content)
              }}
            </td>
            <td></td>
          </tr>
          <tr class="bg-alternative row-two-headers">
            <td :headers="'beregning-' + getId + '-Række' + (index + 1)" v-for="(row, index) in this.content.rowColHeaders[1]" :key="'row2-' + index" v-html="row"></td>
            <td></td>
          </tr>
          <tr class="bg-alternative row-two-data">
            <td :headers="'beregning-' + getId + '-Række' + (index + 1)" :data-cy-id="'Felt' + (index + 6)" v-for="(row, index) in this.content.rowColData[1]" :key="'row2-data-' + index" v-html="doPrettify(row, 2, true) || notRelevant"></td>
            <td></td>
          </tr>

          <tr class="bg-alternative row-two-data"> <!-- v-show="this.content.remarksFieldValue || fileList || this.content.readonly" --> <!-- (fileList?.length > 0) -->
            <td colspan="5" style="padding: 0">
              <ul class="accordion">
                <li style="margin: 0">
                  <h2>
                    <button :aria-controls="'accordion_' + getId" :aria-expanded="this.content.readonly" class="accordion-button">
                      {{
                        (this.content.remarksFieldValue || (fileList?.length > 0)) ? this.calculatorRemarksFilesAccordion : this.calculatorNoRemarksFilesAccordion
                      }}
                    </button>
                  </h2>
                  <div :id="'accordion_' + getId" :aria-hidden="!this.content.readonly" class="accordion-content">
                    <div class="flex justify-content-center remarks-and-files">
                      <div style="text-align: left;  flex-basis: 30%; word-break: break-word;" data-cy-id="Scope3Kategori">
                        <p>
                          <strong>{{ content.scope3CategoryLabel }}</strong>
                        </p>
                        <p>{{ this.asContentfulString(content.scope3CategorySelection) }}</p>
                      </div>
                      <div style="text-align: left;  flex-basis: 40%; word-break: break-word; white-space: pre-line;" data-cy-id="Bemaerkninger">
                        <p>
                          <strong>{{ this.content.remarksFieldLabel }}</strong>
                        </p>
                        <p v-html="this.content.remarksFieldValue"></p>
                      </div>
                      <div style="text-align: left; flex-basis: 30%;" data-cy-id="Vedhaeftninger">
                        <p>
                          <strong>{{ attachedFiles }}</strong>
                        </p>
                        <ul v-if="fileList" class="file-list">
                          <li v-for="(file, index) in fileList" :key="file?.fileName">
                            <a @click="openFile(index)">{{ file?.fileName }}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </td>
            <td :class="isCopy(this.content) ? 'marked-as-copy' : ''"></td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- Delete confirmation modal START-->
      <div v-if="confirmDeleteModel" aria-hidden="false" class="fds-modal">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title"><SimpleText text-key="genericcalculation.deleteModalTitle"/></h2>
            <button aria-label="Luk" class="modal-close button button-unstyled" @click="confirmDeleteModel = false">
              <svg aria-hidden="true" class="icon-svg" focusable="false">
                <use xlink:href="#close"></use>
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <h5><SimpleText text-key="genericcalculation.deleteModalText1"/></h5>
            <br/>
            {{ calculationName }}
            <br/>
            <p><SimpleText text-key="genericcalculation.deleteModalText2"/></p>
          </div>

          <div class="modal-footer">
            <button class="button button-primary" @click="deleteCalculation()">
              <SimpleText text-key="genericcalculation.deleteModalButton1"/>
            </button
            >
            <button class="button button-secondary" @click="confirmDeleteModel = false">
              <SimpleText text-key="genericcalculation.deleteModalButton2"/>
            </button>
          </div>
        </div>
      </div>
      <div v-if="confirmDeleteModel" id="modal-backdrop" class="modal-backdrop"></div>
      <!-- Delete confirmation modal END-->
    </div>
  </div>
</template>

<script lang="ts">
import {Options} from "vue-class-component"
import {Prop} from "vue-property-decorator"
import {FileMessage, FileResource} from "../../../openapi/generated-clients/climatecompass"
import {aiClient, configurationClient, fileClient} from "@/shared/BackendService"
import GenericTableComponentClass from "@/types/component/GenericTableComponentClass"
import BaseComponent from "@/components/calculator/base-components/BaseComponent"
import {Constants} from "@/shared/Constants";
import {unprettify} from "@/shared/PrettifyNumbers";
import SimpleText from "@/components/SimpleText.vue";


@Options({
  components: {
    SimpleText
  },
  emits: ["edit-click", "delete-click", "showToastAndDelayHide"]
})
export default class GenericTableComponent extends BaseComponent {
  //Properties
  @Prop()
  id?: number
  @Prop()
  content?: GenericTableComponentClass = {}

  //Reactive Data
  calculationId? = 0
  calculateId? = 0

  confirmDeleteModel = false
  fileList: Partial<FileMessage[]> = []
  files: Partial<FileResource[]> = []
  aiActive = false

  async beforeMount() {
    await configurationClient
        .getKey('AI_ENABLE', true)
        .then((response) => {
          this.aiActive = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  // if there exist a fieldvalue with a contenful key and text match, then return the translation
  getTranslation(text: string, data: GenericTableComponentClass) {
    if (data && data.fieldValues) {
      for (const val of data.fieldValues) {
        if (val.value === text && val.contentfulId !== null) {
          return this.store.getters.getContentfulContent.findSimpleText(val.contentfulId)
        }
      }
    }
    return text
  }

  asContentfulString(value: string) {
    const numValue = Number(value.substring(Constants.FIELD_ENUM_ID_PREFIX.length))
    //console.log("asContentfulString", value, numValue)
    if (isNaN(numValue)) {
      // default just return input
      return value
    }

    if (Constants.SCOPE3_CATEGORY_1_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category1')
    } else if (Constants.SCOPE3_CATEGORY_2_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category2')
    } else if (Constants.SCOPE3_CATEGORY_3_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category3')
    } else if (Constants.SCOPE3_CATEGORY_4_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category4')
    } else if (Constants.SCOPE3_CATEGORY_5_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category5')
    } else if (Constants.SCOPE3_CATEGORY_6_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category6')
    } else if (Constants.SCOPE3_CATEGORY_7_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category7')
    } else if (Constants.SCOPE3_CATEGORY_8_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category8')
    } else if (Constants.SCOPE3_CATEGORY_9_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category9')
    } else if (Constants.SCOPE3_CATEGORY_10_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category10')
    } else if (Constants.SCOPE3_CATEGORY_11_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category11')
    } else if (Constants.SCOPE3_CATEGORY_12_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category12')
    } else if (Constants.SCOPE3_CATEGORY_13_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category13')
    } else if (Constants.SCOPE3_CATEGORY_14_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category14')
    } else if (Constants.SCOPE3_CATEGORY_15_IDS.includes(numValue)) {
      return this.store.getters.getContentfulContent.findSimpleText('masterdata.category15')
    } else {
      // default just return input
      return value
    }
  }

  get calculationName() {
    if (this.content && this.content.rowColData) {
      return String(this.content.rowColData[0][0])
    }
    return ""
  }

  get doUnprettify() {
    return unprettify
  }

  get getId() {
    return this.id
  }

  isCopy(content: GenericTableComponentClass) {
    if (!content.state) {
      return false;
    }
    if (content.state.toUpperCase() === 'DUPLICATED') {
      return true
    } else {
      return false
    }
  }

  //Computed (Cached)
  get getRowColHeaders() {
    return this.content?.rowColHeaders
  }

  get notRelevant() {
    return `<i>${this.notRelevant2}</i>`
  }

  async updated() {
    //DKFDS.init()
  }

  async mounted() {
    //DKFDS.init()
    await this.getFiles()
  }

  confirmDeleteCalculation() {
    this.confirmDeleteModel = true
  }

  async aiClick(content: GenericTableComponentClass) {
    //console.log("ai", content)
    let result = {}
    let questionPrompt
    let question = ''
    if (content) {
      const unit = content.fieldValues ? content.fieldValues[3].value : ''
      if (content.emf === 'EMF_34' || content.emf === 'EMF_36') {
        const unit = content.fieldValues ? content.fieldValues[2].value : ''
        question = 'fjernvarme ' + unit;
      } else if (content.rowColHeaders && content.emf === 'EMF_30') {
        const q = content.rowColHeaders[0].flat()[2]
        question =  q + ' ' + unit;
      } else if (content.emf === 'EMF_81') {
        const topic = content.fieldValues ? content.fieldValues[1].value : ''
        const topic2 = content.fieldValues ? content.fieldValues[2].value : ''
        const unit = content.fieldValues ? content.fieldValues[4].value : ''
        question = topic + ' ' + topic2 + ' ' + unit;
      } else {
        const topic = content.fieldValues ? content.fieldValues[1].value : ''
        question =  topic + ' ' + unit;
      }
      questionPrompt = prompt("Spørgsmål", 'Reduktion af CO2 ' + question);
      if (questionPrompt) {
        await aiClient.getAiAnswer(questionPrompt).then((response) => {
          if (response.data) {
            result = response.data
          }
        })
        const win = window.open('', 'AI svar', 'height=600,width=800')
        if (win) {
          result.toString().replaceAll('\n', '<br>')
          win.document.write('<p>Spørgsmål:<br>' + questionPrompt + '<br><br>Svar:<br>' + result.toString().replaceAll('\n', '<br>') + '</p>')
        }
      }
    }
  }

  deleteCalculation() {
    this.$emit("delete-click")
    this.$emit("showToastAndDelayHide")
    this.confirmDeleteModel = false
  }

  downloadBase64File(contentBase64: string, fileName: string, fileType: string) {
    const linkSource = `data:application/${fileType};base64,${contentBase64}`
    const downloadLink = document.createElement("a")
    document.body.appendChild(downloadLink)

    downloadLink.href = linkSource
    downloadLink.target = "_blank"
    downloadLink.download = fileName
    downloadLink.click()
  }

  openFile(index: number) {
    const name = this.fileList[index]?.fileName
    const type = this.fileList[index]?.fileType
    const base = this.files[index]?.data
    /*
        To test consider uncommenting the following line:
        window.open('data:application/octet-stream;base64,' + base, '_blank')?.focus();
     */
    if (base && name && type) {
      this.downloadBase64File(base, name, type)
    }
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get attachedFiles() {
    return this.store.getters.getContentfulContent.findSimpleText('calculation.label.attached-files')
  }

  async getFiles(filesFromParent: any[] = []) {
    if (filesFromParent.length > 0) {
      this.fileList = filesFromParent
    } else if (this.id) {
     /* await fileClient
          .getFilesByCalculationLineId(this.id, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')
          .then((result) => {
            const files = result
            this.fileList = files.data.files ? files.data.files : []
          })
          .catch((error) => {
            console.error("[Advarsel] Indlæsningsfejl: " + error)
          })*/
      this.fileList = this.content?.files?.files ? this.content.files.files : []
    }

    for (const file of this.fileList) {
      if (file && file.fileId) {
        const index = this.fileList.indexOf(file)
        await fileClient
            .getFileByFileId(file.fileId, this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')
            .then((response) => {
              this.files[index] = response.data
            })
            .catch((error) => {
              console.error("[Advarsel] Indlæsningsfejl: " + error)
            })
      }
    }
  }

  get calculatorRemarksFilesAccordion() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.calculatorRemarksFilesAccordion')
  }

  get calculatorNoRemarksFilesAccordion() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.calculatorNoRemarksFilesAccordion')
  }

  get notRelevant2() {
    return this.store.getters.getContentfulContent.findSimpleText('sharedContent.notRelevant')
  }

  isNullOrWhitespace( input : string ) : boolean {
    return !input || !input.trim();
  }
}
</script>

<style lang="scss" scoped>
.edit {
  opacity: 0.5 !important;
  position: relative;
}

.row-one-headers {
  border-bottom: none !important;
}

.row-one-headers th {
  padding-bottom: 0;
}

.text-align-right {
  text-align: right !important;
}

.row-two-headers td {
  font-weight: bold;
  border-bottom: none;
  padding-bottom: 0;
}

.row-two-data td {
  text-align: right;
}

.table {
  table-layout: fixed;
  border: 1px solid $color-primary;
  width: 100%;
}

.table.marked-border-as-copy {
  border-color: $color-calculation-copy;
}

.table th:first-child,
.table td:first-child {
  border-left: 1rem solid $color-primary;
}

.table.marked-border-as-copy th:first-child,
.table.marked-border-as-copy td:first-child {
  border-left-color: $color-calculation-copy;
}

.table th:nth-last-child(2),
.table td:nth-last-child(2) {
  border-right: none;
}

.table th:last-child,
.table td:last-child {
  color: white;
  background-color: $color-primary;
  border-right: none;
  border-bottom: 1px solid $color-primary;
  width: 2.5rem;
  padding: 8px 0;
}

.table.marked-border-as-copy th:last-child,
.table.marked-border-as-copy td:last-child {
  background-color: $color-calculation-copy;
  border-bottom: $color-calculation-copy;
}

.overflow-menu .button-overflow-menu {
  min-width: 0;
  min-height: 0;
  padding: 0;
}

.bg-white {
  background-color: white !important;
}

.accordion-button {
  border: 0;
  border-bottom: 1px solid #dcdcdc;

  &:focus {
    outline: none;
  }
}

.accordion-button[aria-expanded='true'] {
  background-image: url('~@/assets/minus-black.svg');
  background-color: white !important;
}

.accordion-button[aria-expanded='false'] {
  background-image: url('~@/assets/plus-black.svg');
  background-color: white !important;
}

.accordion-content {
  .remarks-and-files {
    grid-gap: 10px;
  }

  .file-list {
    list-style-type: none;
    list-style-image: none;

    > li {
      padding-left: 0;

      &::before {
        content: none;
      }

      a {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
</style>
