<template>

  <div class="container px-0">

    <div class="py-3">
      <div class="container px-0 d-print-pagebreak">
        <h3>
          <SimpleText :text-key="'forecast.resultview.scope3-categories-chart.header'"></SimpleText>
        </h3>
        <div data-cy-id="forecast-result-categories-scope3" class="bg-alternative-green">
          <div style="padding: 20px;" v-html="resultCategorySummaryScope3"></div>
        </div>
      </div>
      <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
            @invalid.capture.prevent="formValidation.handleFormValidity"
            ref="formGroup">
        <div class="py-4 row form-group">

          <div class="col-md-5 form-group">
            <label id="multiselect-scope3-categories-label" class="form-label " for="scope3-category-select">
              <SimpleText text-key="forecast.result.scope3.field.category.name" />
            </label>
              <Multiselect
                  id="multiselect-scope3-categories"
                  v-model="selectedCategories"
                  mode="tags"
                  :close-on-select="false"
                  :hideSelected="false"
                  :canClear="false"
                  :groups="true"
                  :options="[
                               {
                                label: 'Alle opstrømskatagorier',
                                options: possibleUpstreamCategories,
                                },
                                {
                                 label: 'Alle nedstrømskatagorier',
                                options: possibleDownstreamCategories,
                                },
                            ]"
                  valueProp="categoryId"
                  label="categoryPresentationName"
                  ref="forecast-category-select"
                  class="multiselect-calculation"
                  :aria="{'aria-labelledby': 'multiselect-scope3-categories-label'}"
                  :placeholder="getContentfulString('forecast.filter.placeholder.all-Categories-selected')"

              />
          </div>
          <div class="col-md-5 form-group">
            <button id="submit-scope3-filter" class="button button-primary" style="margin-top: 8px;" @click="this.refreshChart" :disabled="isPending" >
              <SimpleText text-key="forecast.result.scope123.button.submit.name" />
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="forecast.forcastHeader" id="forecast-categories-result-chart">
      <h4><SimpleText text-key="forecast.resultview.chartheader" /></h4>
      <Scope3CategoryColumnChart v-if="!isLoadingPartially && !isPending && !hasFailed" ref="columnChart" :raw-data="chartSettings" :optimalColumnWidth="AdjustedOptimalWidth"></Scope3CategoryColumnChart>

      <div class="forecast-column-chart-placeholder" v-else-if="hasFailed">
        <div style="align-self: center;">
          <svg class="icon-svg" aria-hidden="true" style="display:block; text-align: center; margin: auto;"><use xlink:href="#report-problem"></use></svg>
          <SimpleText :text-key="'forecast.resultview.errorcalculatingforecast.message'"/>
        </div>
      </div>
      <div class="forecast-column-chart-placeholder" v-else>
        <div style="align-self: center;">
          <div aria-label="Indlæser" class="spinner" />
          <div class="spinner-status" role="status">
            <SimpleText :text-key="'forecast.result.scope123.pendingload.chart'"></SimpleText>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import {FormValidation} from "@/shared/FormValidation";
import {
  CategoryResult,
  ForecastInfo, ForecastInfoMessageStatusEnum,
  ForecastResults,
  GroupingCalculatedResult,
  GroupingCalculatedResults
} from "../../../openapi/generated-clients/climatecompass";
import {Prop, Watch} from "vue-property-decorator";
import DKFDS from "dkfds";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import ContentfulContent from "@/shared/ContentfulContent";
import Multiselect from "@vueform/multiselect";
import ForecastColumnChart from "@/components/forecast/ForecastColumnChart.vue";
import {Constants} from "@/shared/Constants";
import Scope3CategoryColumnChart from "@/components/forecast/Scope3CategoryColumnChart.vue";
import CalculateForecast from "./CalculateForecast.vue";

class CategoriesForSelect {
  categoryId: string
  categoryPresentationName: string

  constructor(categoryId: string, categoryPresentationName: string) {
    this.categoryId = categoryId
    this.categoryPresentationName = categoryPresentationName
  }
}

class CategoriesForSelectWithLabels {
  label: string
  options: CategoriesForSelect[]

  constructor(_label: string, _options: CategoriesForSelect[]) {
    this.label = _label
    this.options = _options
  }
}

@Options({
  components: {
    Scope3CategoryColumnChart,
    RichText,
    SimpleText,
    Tooltip,
    Multiselect,
    ForecastColumnChart
  }
})
export default class Scope3CategorySelect extends BaseComponent {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode?: string

  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent
  resultCategorySummaryScope3 = this.getContentfulString('forecast.resultview.scope3-categories-chart.summery')

  selectedCategories: string[] = []
  selectedStringCategories = [] as string[]
  selectedObjectCategories = [] as CategoriesForSelect[]

  formValidation = new FormValidation()
  showSuccess = false
  contentMessage = ""

  editExistingForecast = false

  @Prop()
  forecast: ForecastResults = {}

  resultHeadlineSummary: any = {}

  possibleCategories: CategoriesForSelect[] = []
  possibleUpstreamCategories: CategoriesForSelect[] = []
  possibleDownstreamCategories: CategoriesForSelect[] = []
  categoriesForSelectWithLabels: CategoriesForSelectWithLabels[] = []

  AdjustedOptimalWidth = this.categoriesForSelectWithLabels.length > 1? 70 : 20

  $refs!: {
    categoriesMultiselect: Multiselect
    formGroup: HTMLFormElement
    columnChart: Scope3CategoryColumnChart
  }

  @Watch('selectedCategories')
  adjustCategoryBarChartWidth() {
    console.log('triggered watcher...');

   const defaultWidth = this.categoriesForSelectWithLabels.length <= 1 ? 20 : 70;

    switch (this.selectedCategories.length) {
      case 0:
        this.AdjustedOptimalWidth = defaultWidth;
        break;
      case 1:
        this.AdjustedOptimalWidth = 20;
        break;
      case 2:
        this.AdjustedOptimalWidth = 40;
        break;
      case 3:
      case 4:
      case 5:
        this.AdjustedOptimalWidth = 70;
        break;
      default:
        this.AdjustedOptimalWidth = 70;
        break;
    }
  }


  /*@Watch('forecast')
  forecastWatch() {
    if (this.forecast && this.forecast.calculations && this.$refs.columnChart) {
      console.log('watched forecast change. Updating chart..')
      this.$refs.columnChart.updateChart(this.chartSettings)
    }
  } */

  get isPending() {
    return this.forecast && this.forecast.calculations && this.forecast?.calculations.length === 0
  }

  get isLoadingPartially() {
    if (this.forecast && this.forecast.forcastHeader) {
      return !this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus === ForecastInfoMessageStatusEnum.UNKNOWN &&
          this.forecast.calculations?.length === 0
    }
    return false
  }

  get hasFailed() {
    if (this.forecast && this.forecast.forcastHeader) {
      return this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus !== ForecastInfoMessageStatusEnum.UNKNOWN
    }
    return false
  }

  get pendingForecasts() {
    return this.store.getters.getPendingForecasts
  }

  get chartSettings() {
    let selectedCategories = [...this.selectedCategories]; // Create a copy of the array
    const categoryFiltered: ForecastResults = {calculations: []};
    const currentForecast = this.forecast

    // Remove 'dummy' ALL selects
    selectedCategories = selectedCategories.filter((category) => {
      if (selectedCategories.includes('ALL_UPSTREAMS') || selectedCategories.includes('ALL_DOWNSTREAMS')) {
        // Check conditions to determine whether to remove the category
        if (category === 'ALL_UPSTREAMS' || category === 'ALL_DOWNSTREAMS') {
          // Do not include 'ALL_UPSTREAM' or 'ALL_DOWNSTREAM'
          return false;
        }
        // Include other categories
        return true;
      }
      // Include all categories if 'ALL_UPSTREAM' and 'ALL_DOWNSTREAM' are not selected
      return true;
    });

    currentForecast.calculations?.forEach((scope3Result: GroupingCalculatedResults) => {
      if (scope3Result.year === this.forecastBasisYear || scope3Result.year === this.forecastEndYear) {
        // Check if any categoryResult names are included in selectedCategories
        const filteredCategoryResults = (scope3Result.categoryResults || []).filter(categoryResult =>
            categoryResult?.categoryName !== undefined && selectedCategories.includes(categoryResult.categoryName)
        );

        if (filteredCategoryResults.length > 0) {
          categoryFiltered.calculations?.push({...scope3Result, categoryResults: filteredCategoryResults});
        }
      }
    })

    if (categoryFiltered.calculations?.length != 0) {
    return categoryFiltered
  } else {
      return currentForecast;
    }
  }

  refreshChart() {
    if (this.$refs.columnChart) {
      this.$refs.columnChart.getChart();
      this.$refs.columnChart.updateChartWidth();
    } else {
      console.error('Chart ref is not available.');
    }
  }

  async beforeMount() {
    this.filterForecastScope3Categories()
    this.AdjustedOptimalWidth = this.categoriesForSelectWithLabels.length > 1? 70 : 20
    console.log('AdjustedOptimalWidth beforeMount = ' + this.AdjustedOptimalWidth)
   if(this.$refs.columnChart) {
     if(this.$refs.columnChart.optimalColumnWidth != undefined) {
       this.$refs.columnChart.updateChartWidth();
     }
     this.$refs.columnChart.getChart()
     this.refreshChart()
   }
  }

  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset();
    }
    this.selectedCategories = []
    this.$refs.categoriesMultiselect?.$emit("clear")
  }

  filterForecastScope3Categories() {
    const possibleCategoriesFilter: CategoryResult[] = [];

    if (this.forecast.calculations && this.forecast?.calculations.length > 0) {
      this.forecast.calculations.forEach((scope3Result: GroupingCalculatedResults) => {
        if ((scope3Result.year === this.forecastBasisYear) && scope3Result.categoryResults) {
          possibleCategoriesFilter.push(...(scope3Result.categoryResults as CategoryResult[]))
        }
      });
    }
    possibleCategoriesFilter.forEach((result: CategoryResult) => {
      if (result.categoryName === this.constants.SCOPE3_CATEGORY_1 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_1, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category1')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_1, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category1')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_2 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_2, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category2')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_2, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category2')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_3 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_3, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category3')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_3, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category3')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_4 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_4, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category4')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_4, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category4')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_5 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_5, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category5')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_5, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category5')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_6 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_6, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category6')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_6, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category6')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_7 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_7, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category7')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_7, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category7')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_8 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_8, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category8')))
        this.possibleUpstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_8, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category8')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_9 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_9, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category9')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_9, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category9')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_10 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_10, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category10')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_10, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category10')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_11 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_11, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category11')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_11, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category11')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_12 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_12, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category12')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_12, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category12')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_13 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_13, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category13')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_13, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category13')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_14 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_14, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category14')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_14, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category14')))
      } else if (result.categoryName === this.constants.SCOPE3_CATEGORY_15 && result.categoryResult != 0) {
        this.possibleCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_15, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category15')))
        this.possibleDownstreamCategories.push(new CategoriesForSelect(this.constants.SCOPE3_CATEGORY_15, this.contentfulContent.findSimpleText('calculation.dropdown.possiblevalue.unit.category15')))
      } else {
        return
      }
      this.categoriesForSelectWithLabels.push(new CategoriesForSelectWithLabels("xx", this.possibleCategories))
      //console.log('categoriesForSelectWithLabels = ' + JSON.stringify(this.selectedCategories))
    })
  }

  async submitForm() {
    this.eventHub.emit('forecast-results-selected-categories', this.selectedCategories)
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  get forecastEndYear() {
    if (this.forecast?.calculations && this.forecast?.calculations.length > 0) {
      return Math.max(...this.forecast.calculations.map(x => x.year ?? 0))
    }
    return 0
  }

  get forecastBasisYear() {
    if (this.forecast?.calculations && this.forecast.calculations.length > 0) {
      return Math.min(...this.forecast.calculations.map(x => x.year ?? 0))

    }
    return 0
  }
}
</script>


<style src="@vueform/multiselect/themes/default.css">
</style>

<style lang="scss">
.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 5px var(--ms-px) 5px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

#multiselect-scope3-categories-label {
  margin-bottom: 8px;
}

.forecast-column-chart-placeholder {
  display: flex; justify-content: center; height: 350px;
}

.multiselect-calculation.form-group .multiselect-placeholder {
  color: black !important;
}
</style>
