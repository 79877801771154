<template>

  <div class="container px-0">

    <div class="py-3">
      <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
            @invalid.capture.prevent="formValidation.handleFormValidity"
            ref="formGroup">
        <div class="py-4 row form-group">

          <div class="col-md-3 form-group" style=" margin-top: 24px;">
            <label class="form-label " for="input-forecast-criteria">
              <SimpleText text-key="forecast.result.scope123.field.forecast-criteria.name" />
            </label>
            <select class="form-select" name="options" id="input-forecast-criteria" v-model="selectedForecastCriteria">
              <option disabled value=""><SimpleText text-key="generic.input.dropdown.option-select.name" /></option>
              <option :key="1" :value="'SCOPES'">Scopes</option>
              <option :key="2" :value="'CATEGORIES'"><SimpleText text-key="forecast.resultview.filter.option.headeroption" /></option>
              <option v-if="hasGrouping(1)" :key="3" :value="'ENERGY'"><SimpleText text-key="calculator.grouping.energy" /></option>
              <option v-if="hasGrouping(2)" :key="4" :value="'PROCUREMENT'"><SimpleText text-key="calculator.grouping.purchases" /></option>
              <option v-if="hasGrouping(3)" :key="5" :value="'TRANSPORT'"><SimpleText text-key="calculator.grouping.transport" /></option>
              <option v-if="hasGrouping(4)" :key="6" :value="'TRASH'"><SimpleText text-key="calculator.grouping.garbage" /></option>
              <option v-if="hasGrouping(5)" :key="7" :value="'PRODUCTS'"><SimpleText text-key="calculator.grouping.products" /></option>
            </select>
          </div>

          <div class="col-md-3 form-group extra-specific">
            <label id="multiselect-scopes-label" class="form-label " for="input-forecast-scope">
              <SimpleText text-key="forecast.result.scope123.field.forecast-scope.name" />
            </label>
            <div class="multiselect-wrapper">
              <Multiselect
                  v-model="selectedScopes"
                  mode="tags"
                  :close-on-select="false"
                  :hideSelected="false"
                  :canClear="false"
                  :groups="false"
                  :options="possibleScopes"
                  valueProp="scopeId"
                  label="scopePresentationName"
                  id="input-forecast-scope"
                  ref="input-forecast-scope"
                  class="multiselect-calculation form-group"
                  :aria="{'aria-labelledby':'multiselect-scopes-label'}"
                  :placeholder="getContentfulString('forecast.resultview.filter.placeholder.allscopesselected')"

              />
            </div>
          </div>

          <div class="col-md-4 form-group">
            <button id="submit-button-forecast-filter" class="button button-primary" style="margin-top: 30px;" @click="refreshChart()" :disabled="isPending" >
              <SimpleText text-key="forecast.result.scope123.button.submit.name" />
            </button>
          </div>
        </div>
      </form>
    </div>
    <div v-if="forecast.forcastHeader" id="forecast-result-chart">
      <h4><SimpleText text-key="forecast.resultview.chartheader" /></h4>
      <ForecastColumnChart v-if="!isLoadingPartially && !isPending && !hasFailed" ref="columnChart" :raw-data="forecast.calculations?.sort((a, b) => a.year - b.year)" :settings="chartSettings" ></ForecastColumnChart>
      <div class="forecast-column-chart-placeholder" v-else-if="hasFailed">
        <div style="align-self: center;">
          <svg class="icon-svg" aria-hidden="true" style="display:block; text-align: center; margin: auto;"><use xlink:href="#report-problem"></use></svg>
          <SimpleText :text-key="'forecast.resultview.errorcalculatingforecast.message'"/>
        </div>
      </div>
      <div class="forecast-column-chart-placeholder" v-else>
        <div style="align-self: center;">
          <div aria-label="Indlæser" class="spinner" />
          <div class="spinner-status" role="status">
            <SimpleText :text-key="'forecast.result.scope123.pendingload.chart'"></SimpleText>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import Navigation from "@/components/thirdparty/Navigation.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import {Menu} from "@/shared/MenuStructure"
import {FormValidation} from "@/shared/FormValidation";
import {
  ForecastInfo, ForecastInfoMessageStatusEnum,
  ForecastResults,
  GroupingCalculatedResult,
  GroupingCalculatedResults
} from "../../../openapi/generated-clients/climatecompass";
import {Prop, Watch} from "vue-property-decorator";
import DKFDS from "dkfds";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import ContentfulContent from "@/shared/ContentfulContent";
import Multiselect from "@vueform/multiselect";
import ForecastColumnChart from "@/components/forecast/ForecastColumnChart.vue";
import {Constants} from "@/shared/Constants";
import {PendingForecastEntry} from "@/types/PendingForecastEntry";

class ScopeForSelect {
  scopeId: string
  scopePresentationName: string

  constructor(scopeId: string, scopePresentationName: string) {
    this.scopeId = scopeId
    this.scopePresentationName = scopePresentationName
  }
}

class ScopesForSelectWithLabels {
  label: string
  options: ScopeForSelect[]

  constructor(_label: string, _options: ScopeForSelect[]) {
    this.label = _label
    this.options = _options
  }
}

@Options({
  components: {
    RichText,
    SimpleText,
    Tooltip,
    Navigation,
    SharedHeader,
    Multiselect,
    ForecastColumnChart
  }
})
export default class Scope123 extends BaseComponent {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode?: string

  menu = {} as Menu
  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  selectedForecastCriteria = 'SCOPES'
  selectedScopes: string[] = []

  formValidation = new FormValidation()
  showSuccess = false
  contentMessage = ""

  editExistingForecast = false



  @Prop()
  forecast: ForecastResults = {}

  get isPending() {
    return this.forecast && this.forecast.calculations && this.forecast?.calculations.length === 0
  }

  get isLoadingPartially() {
    if(this.forecast && this.forecast.forcastHeader) {
      return !this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus === ForecastInfoMessageStatusEnum.UNKNOWN &&
          this.forecast.calculations?.length === 0
    }
    return false
  }

  get hasFailed() {
    if(this.forecast && this.forecast.forcastHeader) {
      return this.forecast.forcastHeader?.isCreationCompleted &&
          this.forecast.forcastHeader.messageStatus !== ForecastInfoMessageStatusEnum.UNKNOWN
    }
    return false
  }

  resultHeadlineSummary: any = {}

  possibleScopes: ScopeForSelect[] = []
  scopesForSelectWithLabels : ScopesForSelectWithLabels[] =[]

  $refs!: {
    scopesmultiselect: Multiselect
    formGroup: HTMLFormElement
    columnChart: ForecastColumnChart
  }

  hasGrouping(groupingId: number) {
    if(this.forecast && this.forecast.calculations && this.forecast.calculations[0]) {
      return this.forecast.calculations[0].groupingResults?.some((x: GroupingCalculatedResult) =>
           x.groupingId === groupingId
      )
    }
    return false
  }

  get pendingForecasts() {
    return this.store.getters.getPendingForecasts
  }

  @Watch('forecast')
  forecastWatch() {
    if(this.forecast && this.forecast.calculations && this.$refs.columnChart) {
      this.$refs.columnChart.updateChart(this.chartSettings)
    }
  }

  get chartSettings() {
    let setting = 0
    if(this.selectedScopes.includes('Scope1')) {
      setting += Constants.FORECAST_FILTER_SCOPE1
    }
    if(this.selectedScopes.includes('Scope2')) {
      setting += Constants.FORECAST_FILTER_SCOPE2
    }
    if(this.selectedScopes.includes('Scope3')) {
      setting += Constants.FORECAST_FILTER_SCOPE3
    }

    if(this.selectedForecastCriteria.includes('SCOPES') ) {
      setting += Constants.FORECAST_FILTER_SCOPES
    }
    if(this.selectedForecastCriteria.includes('CATEGORIES') ) {
      setting += Constants.FORECAST_FILTER_CATEGORIES
    }
    if(this.selectedForecastCriteria.includes('ENERGY') ) {
      setting += Constants.FORECAST_FILTER_CAT_ENERGY
    }
    if(this.selectedForecastCriteria.includes('PROCUREMENT') ) {
      setting += Constants.FORECAST_FILTER_CAT_PROCUREMENT
    }
    if(this.selectedForecastCriteria.includes('TRANSPORT') ) {
      setting += Constants.FORECAST_FILTER_CAT_TRANSPORT
    }
    if(this.selectedForecastCriteria.includes('TRASH') ) {
      setting += Constants.FORECAST_FILTER_CAT_TRASH
    }
    if(this.selectedForecastCriteria.includes('PRODUCTS') ) {
      setting += Constants.FORECAST_FILTER_CAT_PRODUCTS
    }
        return setting
  }

  refreshChart() {
    this.$refs.columnChart.updateChart(this.chartSettings)
  }

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.forecastTabs

    const currentForecastInfo = this.store.getters.getCurrentForecastInfo;
    if (currentForecastInfo?.forecastId) {
      this.setFormFromExistingForecast(currentForecastInfo)
    }

    this.possibleScopes.push(new ScopeForSelect('Scope1', this.contentfulContent.findSimpleText('forecast.resultview.scope1.text')))
    this.possibleScopes.push(new ScopeForSelect('Scope2', this.contentfulContent.findSimpleText('forecast.resultview.scope2.text')))
    this.possibleScopes.push(new ScopeForSelect('Scope3', this.contentfulContent.findSimpleText('forecast.resultview.scope3.text')))
    this.scopesForSelectWithLabels.push(new ScopesForSelectWithLabels("xx", this.possibleScopes))

  }

  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset();
    }
    this.selectedForecastCriteria  = 'SCOPES'
    this.selectedScopes = []
    this.$refs.scopesmultiselect?.$emit("clear")
  }

  setFormFromExistingForecast(forecastInfo: ForecastInfo) {
    this.selectedForecastCriteria = forecastInfo.graphDisplayCriteria ?? 'SCOPES'
    this.selectedScopes = []
    if (forecastInfo.graphDisplayScope1) {
      this.selectedScopes.push('Scope1')
    }
    if (forecastInfo.graphDisplayScope2) {
      this.selectedScopes.push('Scope2')
    }
    if (forecastInfo.graphDisplayScope3) {
      this.selectedScopes.push('Scope3')
    }
  }

  async submitForm() {
    this.eventHub.emit('forecast-results-selected-forecast-criteria', this.selectedForecastCriteria)
    this.eventHub.emit('forecast-results-selected-scopes', this.selectedScopes)
  }

  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

}
</script>


<style src="@vueform/multiselect/themes/default.css">
</style>

<style lang="scss">
.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 5px var(--ms-px) 5px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

#multiselect-scopes-label {
  margin-bottom: 8px;
}

.forecast-column-chart-placeholder {
  display: flex; justify-content: center; height: 350px;
}

.multiselect-calculation.form-group .multiselect-placeholder {
  color: black !important;
}
//Highly specific selector. Needed to overwrite DKFDS natural inheritance.
.py-4.row.form-group > .col-md-3.form-group:nth-of-type(2).extra-specific {
  margin-top: 24px !important;
}

</style>
